.navbar {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #8c2543;
    border-radius: 1.5rem;
    position: relative;
    opacity: 1;
    animation: 1s opacity, 1s moveNavbarTop;
    box-shadow: 3px 3px 3px #2a283e;
    margin-top:2.5rem;
}

.navbar ul {
    list-style-type: none;
    padding: 0;
}

.navbar ul > li {
    display: inline-block;
    padding: 0.25rem;
}

.modalNavbar ul {
    list-style-type: none;
    padding: 0;
}

.modalNavbar ul > li {
    display: inline-block;
    padding: 0.25em;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 2.5rem;
    animation: 1s opacity, 1s moveContentTop;
}

.title {
    font-family: "Tangerine Regular", cursive;
    font-size: 3rem;
    font-weight: lighter;
    color: #f0f0f8;
    margin: 1rem;
}

.active {
    background-color: #166995;
    border-radius: 0.5rem;
    border: 1px solid;
}

.gallery {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 1rem 1rem;
    margin-top: 1rem;
}

.img-container .img-link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25rem;
    height: 25rem;
}

.galleryImg {
    max-width: 100%;
    max-height: 100%;
    border-radius: 0.5rem;
    border: 0.1em solid #f0f0f8;
}

.modalNavbar {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #8c2543;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    opacity: 1;
    position: static;
}

.modalContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0.5rem;
}

.socialButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #8c2543;
    margin-bottom: 1rem;
    border-radius: 2rem;
    border-style: solid;
    border-color: #f0f0f8;
    border-width: 1px;
    width: 20rem;
}

#icon {
    border-radius: 75%;
    border: 0.5rem solid #2a586e;
    box-shadow: 0.5rem 0.5rem 0.5rem;
}

#artist {
    text-decoration: underline;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    border: solid 2px #f0f0f8;
    border-radius: 0.5rem;
    display: none;
    position: absolute;
    background-color: #8c2543;
    margin-top:0.5rem;
    min-width: 160px;
    box-shadow: 0 0.5rem 1rem 0 rgba(0,0,0,0.2);
    z-index: 1;
}

.dropdown-content a {
    padding: 3px;
    display: block;
}

.dropdown:hover .dropdown-content {display: block;}

.dropdown:hover .dropbtn {background-color: #3e8e41;}

.arrow {
    border: solid #f0f0f8;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    margin: 0.25em;
}

.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.navButton {
    color: #f0f0f8;
    text-decoration: none;
    margin: 0.5rem;
    padding: 0.5rem;
    cursor: pointer;
}

.navButtonSocial {
    color: #f0f0f8;
    text-decoration: none;
    margin: 0.5rem;
    padding: 0.5rem;
    cursor: pointer;
}

.tooltip {
}

.tooltip:before {
    content: '';
    display: none;
}

.tooltip:after {
    content: 'Copy to Clipboard';
    display: none;
}

.tooltip:hover:before {
    display:block;
}

.tooltip:hover:after {
    display:block;
}

.tooltip:active:after, .tooltip:focus:after {
    content: 'Copied!';
}

@media (max-width: 800px) {
    #icon {
        width: 10rem;
        height: 10rem;
    }
    .content {
        top: 0;
        position: relative;
        text-align: center;
        margin-top: 1em;
    }
    .navbar {
        position: relative;
    }
    .navbar ul > li {
        display: block;
        text-align: center;
    }

    @keyframes moveNavbarTop {
    }

    @keyframes moveContentTop {
    }

    .navButton {
        color: #f0f0f8;
        text-decoration: none;
        margin-bottom: 1rem;
        padding: 0.5rem;
        cursor: pointer;
    }

}