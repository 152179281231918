.page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1rem;
}

.page-enter {
    opacity: 0;
    transform: translateY(75%);
}

.page-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: opacity 500ms, transform 500ms;
}

.page-exit {
    opacity: 1;
    transform: translateX(0%);
}

.page-exit-active {
    opacity: 0;
    transform: translateX(75%);
    transition: opacity 500ms, transform 500ms;
}

@keyframes opacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes moveNavbarTop {
    0% {
        top: 50%;
    }
    100% {
        overflow: hidden;
        top: 0;
    }
}

@keyframes moveContentTop {
    0% {
        top: 100%;
    }
    100% {
        overflow: hidden;
        top: 0;

    }
}