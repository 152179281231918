html, body, #root {
    height: 100vh;
    background: linear-gradient(135deg, #8c2543 0%, rgba(42, 40, 62, 1) 50%, #166995 100%) no-repeat fixed;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

body::-webkit-scrollbar {
    display: none;
}

#root {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@font-face {
    font-family: "Source Sans Pro";
    src: url("../fonts/source-sans-pro.light.ttf");
}

@font-face {
    font-family: "Tangerine Regular";
    src: url("../fonts/Tangerine-Regular.ttf");
}

a {
    color: #f0f0f8;
}


p {
    font-size: 1rem;
    font-weight: bold;
    color: #f0f0f8;
    margin: 1rem;
}

a {
    font-size: 1.25rem;
}

button {
    background-color: var(--primary-color);
    border: none;
    font-size: 1.25rem;
}